// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_alert_box__mc1wa {
    bottom: 0;
    position: absolute;
    width: 70.3125vh;
    height: 13.359375vh;
    border-radius: 0.703125vh;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2.8125vh;
    box-shadow: 0px 4px 4px 0px var(--black-25);
    z-index: 2000;
}

.Toast_error_toast__YcaYW {
    border: 1px solid var(--rejected-0);
    background: var(--rejected-1);
    /* background-color: #F7EAE8; */
}

.Toast_success_toast__8J3U8 {
    border: 1px solid var(--purple-0);
    background: var(--purple-1);
    /* background-color: #E9E6EE; */
}

.Toast_alert_title__ksoBL {
    font-size: 2.8125vh;
}

.Toast_alert_-description__T3RiX {
    font-size: 1.96875vh;
    color: var(--light-grey-0);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/toast/Toast.module.css"],"names":[],"mappings":"AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,SAAS;IACT,2BAA2B;IAC3B,uBAAuB;IACvB,2CAA2C;IAC3C,aAAa;AACjB;;AAEA;IACI,mCAAmC;IACnC,6BAA6B;IAC7B,+BAA+B;AACnC;;AAEA;IACI,iCAAiC;IACjC,2BAA2B;IAC3B,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,0BAA0B;AAC9B","sourcesContent":["@import url(\"../../../styles/color.css\");\n\n.alert_box {\n    bottom: 0;\n    position: absolute;\n    width: 70.3125vh;\n    height: 13.359375vh;\n    border-radius: 0.703125vh;\n    left: 50%;\n    transform: translateX(-50%);\n    margin-bottom: 2.8125vh;\n    box-shadow: 0px 4px 4px 0px var(--black-25);\n    z-index: 2000;\n}\n\n.error_toast {\n    border: 1px solid var(--rejected-0);\n    background: var(--rejected-1);\n    /* background-color: #F7EAE8; */\n}\n\n.success_toast {\n    border: 1px solid var(--purple-0);\n    background: var(--purple-1);\n    /* background-color: #E9E6EE; */\n}\n\n.alert_title {\n    font-size: 2.8125vh;\n}\n\n.alert_-description {\n    font-size: 1.96875vh;\n    color: var(--light-grey-0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert_box": `Toast_alert_box__mc1wa`,
	"error_toast": `Toast_error_toast__YcaYW`,
	"success_toast": `Toast_success_toast__8J3U8`,
	"alert_title": `Toast_alert_title__ksoBL`,
	"alert_-description": `Toast_alert_-description__T3RiX`
};
export default ___CSS_LOADER_EXPORT___;
