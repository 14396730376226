// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../utils/fonts/fonts.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../utils/images/select-arrow-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../utils/images/not-select-arrow-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_select__cHI9k { /* Firefox */ /* Safari and Chrome */    
    appearance: none;
    padding: 0rem 11.25vh;
    font-family: "Poppins-Medium", sans-serif;
}

.Select_select__cHI9k:disabled {
    opacity: 0.5;
}

.Select_select_small__yDG8V {
    height: 4.78125vh;
    font-size: 1.96875vh;
    border-radius: 0.703125vh;
    border: 0.140625vh solid var(--black-2);
}

.Select_select_medium__-635i {
    /* For state and district select */
    min-height: 2.25vh;
    border: none;
    font-size: 1.96875vh;
}

.Select_select_medium__-635i::after {
    /* For state and district select */
    min-height: 2.25vh;
    border: none;
    font-size: 1.96875vh;
}

.Select_select_large__mAiD\\+ {
    border-radius: 0.703125vh;
    height: 6.75vh;
    padding: 0rem 1.6875vh;
    font-size: 1.96875vh;
    border: 0.140625vh solid var(--black-2);
}

.Select_select_text_selcted__5qM4B {
    color: var(--black-0);
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat right;
}

.Select_select_text_not_selected__R3IVv {
    color: var(--black-3);
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat right;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/select/Select.module.css"],"names":[],"mappings":"AAGA,wBAC2B,YAAY,EACT,sBAAsB;IAChD,gBAAgB;IAChB,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;IACzB,uCAAuC;AAC3C;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,kCAAkC;IAClC,kBAAkB;IAClB,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sBAAsB;IACtB,oBAAoB;IACpB,uCAAuC;AAC3C;;AAEA;IACI,qBAAqB;IACrB,mEAA8E;AAClF;;AAEA;IACI,qBAAqB;IACrB,mEAAkF;AACtF","sourcesContent":["@import url(\"../../../styles/color.css\");\n@import url(\"../../../utils/fonts/fonts.css\");\n\n.select {\n    -moz-appearance: none; /* Firefox */    \n    -webkit-appearance: none; /* Safari and Chrome */    \n    appearance: none;\n    padding: 0rem 11.25vh;\n    font-family: \"Poppins-Medium\", sans-serif;\n}\n\n.select:disabled {\n    opacity: 0.5;\n}\n\n.select_small {\n    height: 4.78125vh;\n    font-size: 1.96875vh;\n    border-radius: 0.703125vh;\n    border: 0.140625vh solid var(--black-2);\n}\n\n.select_medium {\n    /* For state and district select */\n    min-height: 2.25vh;\n    border: none;\n    font-size: 1.96875vh;\n}\n\n.select_medium::after {\n    /* For state and district select */\n    min-height: 2.25vh;\n    border: none;\n    font-size: 1.96875vh;\n}\n\n.select_large {\n    border-radius: 0.703125vh;\n    height: 6.75vh;\n    padding: 0rem 1.6875vh;\n    font-size: 1.96875vh;\n    border: 0.140625vh solid var(--black-2);\n}\n\n.select_text_selcted {\n    color: var(--black-0);\n    background: url(\"../../../utils/images/select-arrow-icon.svg\") no-repeat right;\n}\n\n.select_text_not_selected {\n    color: var(--black-3);\n    background: url(\"../../../utils/images/not-select-arrow-icon.svg\") no-repeat right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `Select_select__cHI9k`,
	"select_small": `Select_select_small__yDG8V`,
	"select_medium": `Select_select_medium__-635i`,
	"select_large": `Select_select_large__mAiD+`,
	"select_text_selcted": `Select_select_text_selcted__5qM4B`,
	"select_text_not_selected": `Select_select_text_not_selected__R3IVv`
};
export default ___CSS_LOADER_EXPORT___;
