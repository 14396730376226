// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__w0F2C {
    border-radius: 0.703125vh;
    border: 0.140625vh solid var(--black-2);
    height: 6.75vh;
    padding: 1.98vh;
    font-size: 1.96875vh;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/input/Input.module.css"],"names":[],"mappings":"AAEA;IACI,yBAAyB;IACzB,uCAAuC;IACvC,cAAc;IACd,eAAe;IACf,oBAAoB;AACxB","sourcesContent":["@import url(\"../../../styles/color.css\");\n\n.input {\n    border-radius: 0.703125vh;\n    border: 0.140625vh solid var(--black-2);\n    height: 6.75vh;\n    padding: 1.98vh;\n    font-size: 1.96875vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__w0F2C`
};
export default ___CSS_LOADER_EXPORT___;
