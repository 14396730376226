// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-wrapper1 {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    background: var(--purple-0);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-overlay1 {
    position: absolute;
}

.spinner-size {
   width: 11.25vh;
   height: 11.25vh;
   opacity: 1;   
   z-index: 9999;
}

/* spinner lite */

.spinner-lite-wrapper {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-lite-overlay {
    position: absolute; 
    height: 80%;
    width: 80%; 
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/spinner/Spinner.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,YAAY;IACZ,MAAM;IACN,OAAO;IACP,eAAe;IACf,2BAA2B;IAC3B,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;GACG,cAAc;GACd,eAAe;GACf,UAAU;GACV,aAAa;AAChB;;AAEA,iBAAiB;;AAEjB;IACI,YAAY;IACZ,WAAW;IACX,MAAM;IACN,OAAO;IACP,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd","sourcesContent":["@import url(\"../../../styles/color.css\");\n\n.spinner-wrapper1 {\n    height: 100vh;\n    width: 100vw;\n    top: 0;\n    left: 0;\n    position: fixed;\n    background: var(--purple-0);\n    z-index: 9999;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.spinner-overlay1 {\n    position: absolute;\n}\n\n.spinner-size {\n   width: 11.25vh;\n   height: 11.25vh;\n   opacity: 1;   \n   z-index: 9999;\n}\n\n/* spinner lite */\n\n.spinner-lite-wrapper {\n    height: 100%;\n    width: 100%;\n    top: 0;\n    left: 0;\n    position: relative;\n    z-index: 9999;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.spinner-lite-overlay {\n    position: absolute; \n    height: 80%;\n    width: 80%; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
