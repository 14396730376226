// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_progress_container__T\\+vHy {
    padding: 0;
    height: 0.703125vh;
    background-color: var(--purple-1);
    width: 100%;
}

.ProgressBar_progressbar__sukuH {
    background-color: var(--purple-0);
    border-radius: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/progressbar/ProgressBar.module.css"],"names":[],"mappings":"AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,iCAAiC;IACjC,WAAW;AACf;;AAEA;IACI,iCAAiC;IACjC,sBAAsB;AAC1B","sourcesContent":["@import url(\"../../../styles/color.css\");\n\n.progress_container {\n    padding: 0;\n    height: 0.703125vh;\n    background-color: var(--purple-1);\n    width: 100%;\n}\n\n.progressbar {\n    background-color: var(--purple-0);\n    border-radius: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress_container": `ProgressBar_progress_container__T+vHy`,
	"progressbar": `ProgressBar_progressbar__sukuH`
};
export default ___CSS_LOADER_EXPORT___;
