// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Legend_legend__SdtQd {
    border-radius: 0.5625vh;
    height: 1.125vh;
}

.Legend_legend_font_style__lGpOZ {
    font-size: 1.40625vh;
    color: var(--black-0);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/legend/Legend.module.css"],"names":[],"mappings":"AAEA;IACI,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,qBAAqB;AACzB","sourcesContent":["@import url(\"../../../styles/color.css\");\n\n.legend {\n    border-radius: 0.5625vh;\n    height: 1.125vh;\n}\n\n.legend_font_style {\n    font-size: 1.40625vh;\n    color: var(--black-0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend": `Legend_legend__SdtQd`,
	"legend_font_style": `Legend_legend_font_style__lGpOZ`
};
export default ___CSS_LOADER_EXPORT___;
