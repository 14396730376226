// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_searchInput__PdXjY {
    border-radius: 0.703125vh;
    border: 0.140625vh solid var(--black-3);
    padding: 0rem 6.07500vh;
    position: relative;
    font-size: 1.96875vh;
    width: 59.90625vh;
    height: 6.75vh;
}

.Search_icon__QVLhE {
    position: absolute;
    padding: 0rem 2.25vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* transform: translate(56%, 20%); */
}

.Search_suggestions_hidden__ZwaAE {
    height: 0;
    visibility: hidden;
}

.Search_suggestions_visible__fYf53 {
    width: 59.90625vh;
    /* min-height: 6rem;
    max-height: 10rem; */
    overflow-y: scroll;
    font-size: 1.96875vh;
    box-shadow: 0 0.28125vh 0.5625vh 0 rgba(0, 0, 0, .13), 0 0.140625vh 0.140625vh 0 rgba(0, 0, 0, .11);
}

.Search_suggestions_item__nwdHv:hover {
    background-color: var(--purple-0);
    color: var(--white-0);
}

.Search_suggestion_height__GKrAg {
    min-height: 13.5vh;
    max-height: 22.5vh;
}

.Search_suggestion_menu__ncYQS {
	position: relative;
}

.Search_suggestion_menu_dropdown__D7s7f {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	z-index: 1000 !important;
	border-radius: 0.703125vh;
	min-width: 42.1875vh;
	padding: 0;
	box-shadow: 0 0.28125vh 0.5625vh rgba(0, 0, 0, 0.1);
	border: 0.140625vh solid #ccc;
}



`, "",{"version":3,"sources":["webpack://./src/components/ui/search/Search.module.css"],"names":[],"mappings":"AAEA;IACI,yBAAyB;IACzB,uCAAuC;IACvC,uBAAuB;IACvB,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,oCAAoC;AACxC;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB;wBACoB;IACpB,kBAAkB;IAClB,oBAAoB;IACpB,mGAAmG;AACvG;;AAEA;IACI,iCAAiC;IACjC,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,SAAS;CACT,OAAO;CACP,sBAAsB;CACtB,wBAAwB;CACxB,yBAAyB;CACzB,oBAAoB;CACpB,UAAU;CACV,mDAAmD;CACnD,6BAA6B;AAC9B","sourcesContent":["@import url(\"../../../styles/color.css\");\n\n.searchInput {\n    border-radius: 0.703125vh;\n    border: 0.140625vh solid var(--black-3);\n    padding: 0rem 6.07500vh;\n    position: relative;\n    font-size: 1.96875vh;\n    width: 59.90625vh;\n    height: 6.75vh;\n}\n\n.icon {\n    position: absolute;\n    padding: 0rem 2.25vh;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    /* transform: translate(56%, 20%); */\n}\n\n.suggestions_hidden {\n    height: 0;\n    visibility: hidden;\n}\n\n.suggestions_visible {\n    width: 59.90625vh;\n    /* min-height: 6rem;\n    max-height: 10rem; */\n    overflow-y: scroll;\n    font-size: 1.96875vh;\n    box-shadow: 0 0.28125vh 0.5625vh 0 rgba(0, 0, 0, .13), 0 0.140625vh 0.140625vh 0 rgba(0, 0, 0, .11);\n}\n\n.suggestions_item:hover {\n    background-color: var(--purple-0);\n    color: var(--white-0);\n}\n\n.suggestion_height {\n    min-height: 13.5vh;\n    max-height: 22.5vh;\n}\n\n.suggestion_menu {\n\tposition: relative;\n}\n\n.suggestion_menu_dropdown {\n\tposition: absolute;\n\ttop: 100%;\n\tleft: 0;\n\tbackground-color: #fff;\n\tz-index: 1000 !important;\n\tborder-radius: 0.703125vh;\n\tmin-width: 42.1875vh;\n\tpadding: 0;\n\tbox-shadow: 0 0.28125vh 0.5625vh rgba(0, 0, 0, 0.1);\n\tborder: 0.140625vh solid #ccc;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `Search_searchInput__PdXjY`,
	"icon": `Search_icon__QVLhE`,
	"suggestions_hidden": `Search_suggestions_hidden__ZwaAE`,
	"suggestions_visible": `Search_suggestions_visible__fYf53`,
	"suggestions_item": `Search_suggestions_item__nwdHv`,
	"suggestion_height": `Search_suggestion_height__GKrAg`,
	"suggestion_menu": `Search_suggestion_menu__ncYQS`,
	"suggestion_menu_dropdown": `Search_suggestion_menu_dropdown__D7s7f`
};
export default ___CSS_LOADER_EXPORT___;
