// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../../styles/color.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url("assets/fonts/fonts.css"); */


/* contained,
    transparent,
    bordered */

.Card_card_bordered__4POKe{ /* with border and background color */
	background-color: var(--white-1);
	border: 0.140625vh solid var(--black-1);
	border-radius: 0.703125vh;
	color: var(--black-0);
}
.Card_card_contained__xJe44 { /* without border and with background color */
	background-color: var(--white-1);
	border: none;
	border-radius: 0.703125vh;
	color: var(--black-0);
	padding: 2.8125vh;
}

.Card_card_transparent__DLJ1C { /* without border and without background color */
	background-color: transparent;
	border: none;
	border-radius: 0.703125vh;
	color: var(--black-0);
	padding: 2.25vh;
}

/* CardSize */

.Card_card_default__rdRrZ {
	width: 100%;
}
.Card_card_small__gLnQU {
	width: 20.875rem;
}

.Card_card_medium__dO7cr {
	width: 90%;
}

/* .card_large {
	width: 40.5rem;
} */


`, "",{"version":3,"sources":["webpack://./src/components/ui/card/Card.module.css"],"names":[],"mappings":"AACA,2CAA2C;;;AAG3C;;cAEc;;AAEd,4BAAgB,qCAAqC;CACpD,gCAAgC;CAChC,uCAAuC;CACvC,yBAAyB;CACzB,qBAAqB;AACtB;AACA,8BAAkB,6CAA6C;CAC9D,gCAAgC;CAChC,YAAY;CACZ,yBAAyB;CACzB,qBAAqB;CACrB,iBAAiB;AAClB;;AAEA,gCAAoB,gDAAgD;CACnE,6BAA6B;CAC7B,YAAY;CACZ,yBAAyB;CACzB,qBAAqB;CACrB,eAAe;AAChB;;AAEA,aAAa;;AAEb;CACC,WAAW;AACZ;AACA;CACC,gBAAgB;AACjB;;AAEA;CACC,UAAU;AACX;;AAEA;;GAEG","sourcesContent":["@import url(\"../../../styles/color.css\");\n/* @import url(\"assets/fonts/fonts.css\"); */\n\n\n/* contained,\n    transparent,\n    bordered */\n\n.card_bordered{ /* with border and background color */\n\tbackground-color: var(--white-1);\n\tborder: 0.140625vh solid var(--black-1);\n\tborder-radius: 0.703125vh;\n\tcolor: var(--black-0);\n}\n.card_contained { /* without border and with background color */\n\tbackground-color: var(--white-1);\n\tborder: none;\n\tborder-radius: 0.703125vh;\n\tcolor: var(--black-0);\n\tpadding: 2.8125vh;\n}\n\n.card_transparent { /* without border and without background color */\n\tbackground-color: transparent;\n\tborder: none;\n\tborder-radius: 0.703125vh;\n\tcolor: var(--black-0);\n\tpadding: 2.25vh;\n}\n\n/* CardSize */\n\n.card_default {\n\twidth: 100%;\n}\n.card_small {\n\twidth: 20.875rem;\n}\n\n.card_medium {\n\twidth: 90%;\n}\n\n/* .card_large {\n\twidth: 40.5rem;\n} */\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_bordered": `Card_card_bordered__4POKe`,
	"card_contained": `Card_card_contained__xJe44`,
	"card_transparent": `Card_card_transparent__DLJ1C`,
	"card_default": `Card_card_default__rdRrZ`,
	"card_small": `Card_card_small__gLnQU`,
	"card_medium": `Card_card_medium__dO7cr`
};
export default ___CSS_LOADER_EXPORT___;
