// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Popover_popover__o1kBx .Popover_popover-arrow__83sq3 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 1 5px;
    border-color: #5431BB !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Popover_bounce__QidAb {
    animation: Popover_MoveUpDown__xj1Hz 1s linear infinite;
    /* position: absolute;
    left: 0;
    bottom: 0; */
  }
   
  @keyframes Popover_MoveUpDown__xj1Hz {
    0%, 100% {
      bottom: 80%;
    }
    50% {
      bottom: 110%;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/ui/popover/Popover.module.css"],"names":[],"mappings":";AACA;IACI,QAAQ;IACR,SAAS;IACT,mBAAmB;IACnB,2BAA2B;IAC3B,gCAAgC;IAChC,kBAAkB;IAClB,SAAS;IACT,2BAA2B;AAC/B;;AAEA;IACI,uDAAwC;IACxC;;gBAEY;EACd;;EAEA;IACE;MACE,WAAW;IACb;IACA;MACE,YAAY;IACd;EACF","sourcesContent":["\n.popover .popover-arrow {\n    width: 0;\n    height: 0;\n    border-style: solid;\n    border-width: 5px 5px 1 5px;\n    border-color: #5431BB !important;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n}\n\n.bounce {\n    animation: MoveUpDown 1s linear infinite;\n    /* position: absolute;\n    left: 0;\n    bottom: 0; */\n  }\n   \n  @keyframes MoveUpDown {\n    0%, 100% {\n      bottom: 80%;\n    }\n    50% {\n      bottom: 110%;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `Popover_popover__o1kBx`,
	"popover-arrow": `Popover_popover-arrow__83sq3`,
	"bounce": `Popover_bounce__QidAb`,
	"MoveUpDown": `Popover_MoveUpDown__xj1Hz`
};
export default ___CSS_LOADER_EXPORT___;
